import React from 'react';
import LayoutStatic from '../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
    backgroundColor: '#eaf4ff',
  },
};

const HomePage = () => {
  return (
    <LayoutStatic>
      <div className='card' style={style.card}>
        <img
          src='img/Mental.jpg'
          className='card-img-top bootstrap-card-img-top'
          alt='...'
        />
        <div className='card-body'>
          <h5 className='card-title'>ประเมินสุขภาพจิต</h5>
          <p className='card-text'>
            อย่าปล่อยให้สิ่งนี้มีผลกับเรา โดยไม่รู้ตัว
          </p>
          <Link to='/menu/mental' className='btn btn-primary'>
            เริ่มเลย !
          </Link>
        </div>
      </div>

      <div className='card' style={style.card}>
        <img
          src='/img/CardWriting.jpg'
          className='card-img-top bootstrap-card-img-top'
          alt='...'
        />
        <div className='card-body'>
          <h5 className='card-title'>ประเมินพัฒนาการเด็ก</h5>
          <p className='card-text'>(อายุ 1-8 ปี) </p>
          <Link to='/menu/child' className='btn btn-primary'>
            เริ่มเลย !
          </Link>
        </div>
      </div>

      <div className='card' style={style.card}>
        <img
          href='Infant_index.html'
          src='img/InfantDevelopment.jpg'
          className='card-img-top bootstrap-card-img-top'
          alt='...'
        />
        <div className='card-body'>
          <h5 className='card-title'>ประเมินพัฒนาการทารก</h5>
          <p className='card-text'>(อายุ 0-11 เดือน)</p>
          <Link to='/menu/infant' className='btn btn-primary'>
            เริ่มเลย !
          </Link>
        </div>
      </div>

      <div className='card' style={style.card}>
        <img
          src='img/ncd.jpg'
          className='card-img-top bootstrap-card-img-top'
          alt='...'
        />
        <div className='card-body'>
          <h5 className='card-title'>ประเมินโรคทั่วไป</h5>
          <p className='card-text'>
            โรคที่เกิดจากนิสัยหรือพฤติกรรมการดำเนินชีวิต
            ซึ่งจะมีการดำเนินโรคอย่างช้าๆ
          </p>
          <Link to='/menu/ncd' className='btn btn-primary'>
            เริ่มเลย !
          </Link>
        </div>
      </div>

      <div className='card' style={style.card}>
        <img
          src='img/Old.jpg'
          className='card-img-top bootstrap-card-img-top'
          alt='...'
        />
        <div className='card-body'>
          <h5 className='card-title'>ประเมิน โรคในผู้สูงอายุ</h5>
          <p className='card-text'>เมื่ออายุมากขึ้น ก็ต้องใส่ใจมากขึ้น</p>
          <Link to='/menu/old' className='btn btn-primary'>
            เริ่มเลย !
          </Link>
        </div>
      </div>
    </LayoutStatic>
  );
};

export default HomePage;
